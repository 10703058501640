import React from "react"
import InsightCats from "./categoriesInsight"
import TechCats from "./categoriesTech"
import TypeCats from "./categoriesType"
import IndustryCats from "./categoriesIndustry"
import EventCats from "./categoriesEvent"


const Categories = (props) => {
  switch (props.type) {
    case "blogInsight":
      return <InsightCats />

    case "blogTech":
      return <TechCats />

    case "resources":
      return <TypeCats />

    case "caseStudies":
      return <IndustryCats />

    case "event":
      return <EventCats />

    default:
      return null
  }
}

export default Categories
