import React from "react"
import Figure from "./figure"
import Categories from "./categories"

import styles from "./postSidebarFilter.module.scss"

const PostSidebar = props => {
  const handleClick = evt => {
    let button = evt.currentTarget
    button.getAttribute("aria-expanded") === "false"
      ? button.setAttribute("aria-expanded", "true")
      : button.setAttribute("aria-expanded", "false")
  }
  return (
    <aside className={styles.postSidebar}>
      {props.thumbnail && (
        <div className={styles.sidebarThumbnail}>
          <Figure {...props.thumbnail} />
          <p className={styles.sidebarAuthor}>
            By {props.author}
            {props?.secondAuthor && ` and ${props.secondAuthor}`}
          </p>
          <p>{props.date}</p>
        </div>
      )}
      <div className={styles.sidebarItem}>
        <p>Filters</p>
        <button
          aria-expanded="false"
          aria-controls="categories-list"
          onClick={handleClick}
        >
          Filters
        </button>
        <Categories type={props.type} />
      </div>
      {props.form && (
        <div className={styles.sidebarItem}>
          <p>Subscribe to our blog</p>
          <div className={styles.sidebarForm}>
            <iframe
              src={props.form}
              width="100%"
              height="365"
              type="text/html"
              frameBorder="0"
              allowtransparency="true"
            ></iframe>
          </div>
        </div>
      )}
    </aside>
  )
}

export default PostSidebar
