import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { getTotal } from "../helpers/categories"

const TechFilters = (props) => (
  <StaticQuery
    query={graphql`
      {
        allSanityTechCategory {
          edges {
            node {
              slug { current }
              title
              _id
            }
          }
        }
        allSanityBlogTech {
          group(field: category___slug___current) {
            totalCount
            fieldValue
          }
        }
      }
    `}
    render={data => {
      const cats = data.allSanityTechCategory.edges
      const totals = data.allSanityBlogTech.group

      return (
        <ul data-theme="brandGamma" id="categories-list">
          <li>
            <Link to={`/blog/product/`}>
              All
            </Link>
          </li>
          {cats.map(({node: cat}) =>
            getTotal(cat.slug.current, totals) > 0 ? (
              <li key={cat._id}>
                <Link to={`/blog/product/${cat.slug.current}/`}>
                  {cat.title} ({getTotal(cat.slug.current, totals)})
                </Link>
              </li>
            ) : null
          )}
        </ul>
      )
    }}
  />
)

export default TechFilters
