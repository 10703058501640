import React from "react"
import { StaticQuery, graphql } from "gatsby"

const IndustryFilters = (props) => (
  <StaticQuery
    query={graphql`
      {
        allSanityEventCategory {
          edges {
            node {
              slug { current }
              title
              _id
            }
          }
        }
      }
    `}
    render={data => {
      const cats = data.allSanityEventCategory.edges

      return (
        <ul data-theme="brandMid" id="categories-list">
          <li>
            <a href={`/events/`}>All</a>
          </li>
          {cats.map(({node: cat}) =>
            <li key={cat._id}>
              <a href={`/events/${cat.slug.current}/`}>{cat.title}</a>
            </li>
          )}
          <li data-button>
            <a href="/events/past/">View past events</a>
          </li>
        </ul>
      )
    }}
  />
)

export default IndustryFilters
