import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import Post from "../../components/post"
import PostSidebar from "../../components/postSidebarFilter"
import CTA from "../../components/cta"

export const query = graphql`
  query($slug: String) {
    sanityBlogTech(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      publishDate(formatString: "MMM D, YYYY")
      _rawIntro(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawThumbnail(resolveReferences: { maxDepth: 10 })
      _rawAuthor(resolveReferences: { maxDepth: 10 })
      _rawSecondAuthor(resolveReferences: { maxDepth: 10 })
      _rawCta
      _rawGlobalCta(resolveReferences: { maxDepth: 10 })
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
    sanityBlogSettings {
      formLinkTech
    }
  }
`

const InsightTemplate = props => {
  const page = props.data.sanityBlogTech
  const title = page.title
  const cta = page._rawGlobalCta ? page._rawGlobalCta.cta : page._rawCta
  const customTitle =
    page.seo && page.seo.metaTitle ? page.seo.metaTitle : page.title
  const description =
    page.seo && page.seo.description ? page.seo.description : null
  const image =
    page.seo &&
    page.seo.openGraphImage &&
    page.seo.openGraphImage.asset !== null
      ? page.seo.openGraphImage.asset.url
      : page._rawThumbnail.asset.url
  const form = props.data.sanityBlogSettings.formLinkTech

  return (
    <Layout theme="light">
      <Helmet>
        {page.seo && page.seo.noIndex && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title={page.title}
        customTitle={customTitle}
        description={description}
        image={image}
      />
      <Hero heroTitle={title} theme="light" graph="angularLine" />
      <PostWrapper>
        <Post
          intro={page._rawIntro}
          content={page._rawBody}
          author={page._rawAuthor}
          secondAuthor={page?._rawSecondAuthor}
          form={form}
        />
        <PostSidebar
          thumbnail={page._rawThumbnail}
          author={page._rawAuthor.name}
          secondAuthor={page?._rawSecondAuthor?.name}
          date={page.publishDate}
          form={form}
          type="blogTech"
        />
      </PostWrapper>
      {cta && <CTA {...cta} />}
    </Layout>
  )
}

export default InsightTemplate
